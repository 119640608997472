import styles from "./styles/EditDropDown.module.scss"
import FontAwesome from "../../utilities/FontAwesome";
import React, {useContext, useEffect, useState} from "react";
import {interfaceStore, StoreContext} from "../../../stores/StoreLoader";
import {values} from "mobx";
import WatsonApi from "../../../backends/WatsonApi";
import NotificationManager from "../../notifications/NotificationManager";
import classNames from "classnames";
import {useComponentVisible} from "../../../hooks/useComponentVisible";

export interface EditDropDownItem {
    title: string,
    icon: JSX.Element,
    onClick: () => void,
    hideButtonSelector?: boolean,
}

const EditDropDown = (props: {
    blockObj: ITerrace,
    dropDownItems?: EditDropDownItem[],
    className?: string,
    title: string,
}) => {
    const {gridStore} = useContext(StoreContext);
    const [openLeft, setOpenLeft] = useState(false);

    const focusEditButton = () => {
        const editButton = document.getElementById(`${props.blockObj.id}-editbutton`) as HTMLButtonElement;
        if (editButton) editButton.focus();
    }

    const {ref, setIsComponentVisible, isComponentVisible} = useComponentVisible(false, focusEditButton);

    const dropDownContainerClassName = classNames({
        [styles.container]: true,
        [styles.containerLeft]: openLeft,
        [styles.containerVisible]: isComponentVisible,
    });

    const editPencilClassName = classNames({
        [styles.editPencil]: true,
        [styles.editPencilDropdownVisible]: isComponentVisible,
    })

    const focusFirstButton = () => {
        const firstButton = document.getElementById(`${props.blockObj.id}-firstbutton`) as HTMLButtonElement;
        if (firstButton) firstButton.focus();
    }

    const reorderBlocks = async moveUp => {
        try {
            const allSortOrders = values(gridStore.blocks).map(b => b.id);
            const currentPosition = allSortOrders.indexOf(props.blockObj.id);

            const nextPosition = moveUp ? currentPosition - 1 : currentPosition + 1;
            allSortOrders[currentPosition] = allSortOrders.splice(nextPosition, 1, allSortOrders[currentPosition])[0];

            const client = await WatsonApi();
            await client.apis.organizations.organizations_terraces_bulk_update({
                organization_pk: props.blockObj.organization_id,
                data: allSortOrders.map((id, idx) => {
                    const block = gridStore.blocks.get(id);
                    return {
                        id: block.id,
                        type: block.type,
                        organization_id: block.organization_id,
                        json_data: block.json_data,
                        created_at: block.created_at,
                        title: block.title,
                        sort_order: idx,
                    }
                }),
            })

            gridStore.sortBlocksManually(allSortOrders);
        } catch (e) {
            NotificationManager.error("Something went wrong! Please try again.");
        }
    };

    const allOrderedBlocks = values(gridStore.blocks).filter(b => !b.deleted && b.type !== "hero");
    const currentPosition = allOrderedBlocks.findIndex(b => b.type === props.blockObj.type);

    const moveUpDownButtons: EditDropDownItem[] = [
        {
            title: 'Move Up',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-arrow-up"/>,
            onClick: () => reorderBlocks(true),
        },
        {
            title: 'Move Down',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-arrow-down"/>,
            onClick: () => reorderBlocks(false),
        },
    ]

    useEffect(() => {
        const button = document.getElementById(`${props.blockObj.id}-editbutton`)
        if (button) {
            if (button.getBoundingClientRect().x > interfaceStore.width/2) {
                setOpenLeft(true);
            }
            else {
                setOpenLeft(false)
            }
        }
    },[props.title.length])

    return (
        <div className={`${styles.editPencilContainer} ${props.className}`}>
            <div ref={ref}>
                <button aria-label={'Edit Terrace'} id={`${props.blockObj.id}-editbutton`} key="edit" type="button" onClick={() => {
                    setIsComponentVisible(v => !v)
                }}
                        className={editPencilClassName}>
                    <span id={`${props.blockObj.id}-editbuttonspan`} className="fas fa-pencil-alt" role="presentation"/>
                </button>
                <div className={dropDownContainerClassName}>
                    <ul>
                        {props.dropDownItems && props.dropDownItems.map((item, i) => {
                            return <li key={item.title}>
                                <button
                                    data-hide-button={item.hideButtonSelector}
                                    tabIndex={isComponentVisible ? 0 : -1}
                                    id={i === 0 ? `${props.blockObj.id}-firstbutton` : undefined}
                                    onClick={() => {
                                        item.onClick()
                                        setIsComponentVisible(false)
                                    }}>
                                    <span>{item.icon}</span>
                                    <span>{item.title}</span>
                                </button>
                            </li>
                        })}
                        {moveUpDownButtons.map(item => {
                            return <li key={item.title}>
                                <button
                                    tabIndex={isComponentVisible ? 0 : -1}
                                    disabled={(item.title === 'Move Up' && currentPosition === 0) ||
                                        (item.title === 'Move Down' && currentPosition === allOrderedBlocks.length - 1)}
                                    onClick={item.onClick}>
                                    <span>{item.icon}</span>
                                    <span>{item.title}</span>
                                </button>
                            </li>
                        })}
                        <span tabIndex={isComponentVisible ? 0 : -1}
                              onFocus={focusFirstButton}
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default EditDropDown;
