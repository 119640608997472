import {observer} from "mobx-react";
import styles from "./styles/NewsBlock.module.scss"
import BlockTitle from "../_BlockTitle";
import {ITerraceProps} from "../Terrace";
import ViewMoreButton from "../ViewMoreButton";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {CSSTransition} from "react-transition-group";
import {
    IReactiveNewsContentItem,
    ReactiveContentItem,
} from "../../../../stores/SchoolFeedStore";
import InlineTextEditor from "../../../utilities/InlineTextEditor";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import classNames from "classnames";
import EditDropDown, {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import {
    getTerraceTypeFromContentItem,
    SupportedContentItemTypes,
} from "../../../schoolfeed/PostManager/SchoolFeedPostManager";
import {useCreateContentItem} from "../../../../hooks/useCreateContentItem";
import NewsSelector from "./NewsSelector";
import {runInAction, values} from "mobx";
import {Toggle} from "../../SchoolBlocks/EditBlockComponents/EditBlockInputs/Toggle";

interface NewsBlockProps extends ITerraceProps {
    blockObj: Omit<INewsTerrace, "content_items"> & {content_items: IReactiveNewsContentItem[]},
}

const visibilityTransitionClassNames = {
    enter: styles.transitionEnter,
    appear: styles.transitionAppear,
    enterActive: styles.transitionEnterActive,
    appearActive: styles.transitionAppearActive,
    enterDone: styles.transitionEnterDone,
    appearDone: styles.transitionAppearDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const NewsBlock = observer((props: NewsBlockProps) => {

    const {interfaceStore, organizationStore, userStore, sidebarStore, gridStore} = useContext(StoreContext);

    const {handleSave} = useCreateContentItem()

    const showDistrictOnSchoolSite = organizationStore.currentOrganization.type === 'school' && organizationStore.organization.district

    function getUniqueOrgs () {
        const uniqueOrgs: OrganizationTypeOrganization[] = [];

        uniqueOrgs.push(organizationStore.currentOrganization);
        if (organizationStore.organization.id !== organizationStore.currentOrganization.id) {
            uniqueOrgs.push(organizationStore.organization);
        }
        if (showDistrictOnSchoolSite) {
            uniqueOrgs.push(organizationStore.organization.district);
        }

        return uniqueOrgs;
    }

    const uniqueOrgs = getUniqueOrgs();
    const [currentOrgId, setCurrentOrgId] = useState<string>(organizationStore.currentOrganization.id);
    const [customTitle, setCustomTitle] = useState(props.blockObj.title);

    useEffect(() => {
        if (uniqueOrgs.length === 2) {
            const content = props.blockObj.content_items.filter((item) => {
                return item.share_paths.includes(uniqueOrgs[0].path)
            })
            if (content.length > 0) {
                setCurrentOrgId(uniqueOrgs[0].id);
            } else {
                setCurrentOrgId(uniqueOrgs[1].id);
            }
        } else if (uniqueOrgs.length === 1) {
            setCurrentOrgId(uniqueOrgs[0].id);
        }
    },[]);

    const content = props.blockObj.content_items.filter((item, i) => {
        const currentOrg = uniqueOrgs.find(org => org.id === currentOrgId);
        return currentOrg ? item.share_paths.includes(currentOrg.path) : false;
    })

    async function saveTitle() {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: props.blockObj.organization_id,
                id: props.blockObj.id,
                data: {
                    title: customTitle,
                },
            });
            NotificationManager.success("Title successfully saved!");
            setCustomTitle(response.obj.title);
        } catch (e) {
            setCustomTitle(props.blockObj.title);
            NotificationManager.error("Something went wrong! Please try again.");
        }
    }

    const titleContainerClassName = classNames({
        [styles.titleContainer]: true,
        [styles.titleContainerWithBackgroundImage]: !!organizationStore.currentOrganization.backgroundImage,
    })

    const newsOptions = uniqueOrgs.map(object => {
        if (object.id === organizationStore.currentOrganization.id) {
            return {
                value: object.id,
                label: customTitle,
            }
        }

        let title = object.title
        if (object.type === 'school') {
            title = "School News"
        }
        if (object.type === 'district') {
            title = "District News"
        }
        return ({
            value: object.id,
            label: title,
        })
    });

    const handleChangeOrg = (orgId) => {
        setCurrentOrgId(orgId)
    };

    const onClick = () => {
        sidebarStore.setSidebar(
            {
                view: "SchoolFeedPost",
                sbtype: SupportedContentItemTypes.NEWS,
                handleSave: handleSave,
            }
        )
    }

    const newsBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add News Post',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClick,
        },
    ]

    const newsTerraceTitle = () => {
        if (organizationStore.organization.type === 'district' || showDistrictOnSchoolSite) {
            return 'District News'
        }
        else if (organizationStore.organization.type === 'school') {
            return 'School News'
        }
    }

    const titleandEditPencilClassName = classNames({
        [styles.titleAndEditPencil]: organizationStore.currentOrganization.type !== 'district',
    })

    const customTitleComponent =
        <div className={titleContainerClassName}>
            {currentOrgId === organizationStore.currentOrganization.id ? <div className={titleandEditPencilClassName}>
                <InlineTextEditor
                        text={customTitle}
                        canEdit={userStore.isEditor}
                        handleTextChange={setCustomTitle}
                        handleSave={saveTitle}
                        wrapperClassName={styles.editableTitle}
                        placeholder={"Enter a Title"}
                    >

                    <h2>
                        {customTitle}
                    </h2>
                </InlineTextEditor>
                {userStore.editor &&
                    <EditDropDown
                            className={styles.editDropDown}
                            blockObj={props.blockObj}
                            title={customTitle}
                            dropDownItems={newsBlockDropDownItems}/>
                    }
            </div>
            :
            <div className={`${styles.editableTitle} ${styles.editableTitleParent}`}>
                <h2>
                    {newsTerraceTitle()}
                </h2>
            </div>
            }
            {(organizationStore.currentOrganization.type !== 'district' || showDistrictOnSchoolSite) && uniqueOrgs && <Toggle
                value={currentOrgId}
                onChange={handleChangeOrg}
                options={newsOptions}
                className={styles.toggleClassName}
            />}
        </div>

    function handleDelete(contentItem) {
        runInAction(() => {
            const terraceType = getTerraceTypeFromContentItem(contentItem);
            const terrace = values(gridStore.blocks).find(b => b.type === terraceType);
            if (terrace) {
                const index = terrace.content_items.findIndex(item => item.id === contentItem.id);
                if (index !== -1) {
                    terrace.content_items.splice(index, 1);
                }
            }
        })
    }

    return (
        <div className={styles.newsBlockContainer}>
            <div id={'blockTitle'}>
                <BlockTitle blockObj={props.blockObj}
                            aboveTheFold={props.aboveTheFold}
                            blockTitleContainerClassName={styles.blockTitleContainerClassName}
                            dropDownItems={newsBlockDropDownItems}
                            customTitleComponent={customTitleComponent}/>
            </div>
            <div className={styles.transitionWrapper}>
                <CSSTransition key={currentOrgId} appear in={true} classNames={visibilityTransitionClassNames}
                               timeout={250}>
                    <div className={styles.transitionContainer}>
                        {content.length === 0 ?
                            <div className={styles.noContent}>
                                No content to display, please try another filter.
                            </div> :
                            <NewsSelector contentItems={content}
                                          aboveTheFold={props.aboveTheFold}
                                          viewMoreButton={<ViewMoreButton text={"View More News"}
                                                                          terraceType={props.blockObj.type}
                                                                          organizationId={currentOrgId ? currentOrgId : organizationStore.currentOrganization.id}
                                                                          className={styles.viewMoreButton}/>}
                                          getItemAtIndex={idx => {
                                return content.length >= idx ? new ReactiveContentItem(content[idx]) : null;
                                          }}
                                          totalItemCount={content.length} handleDelete={handleDelete}/>
                            }
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
})

export default NewsBlock;
